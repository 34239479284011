
import { TAG_COLOR_ACTIVE } from '@icepanel/app-canvas'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import getColor from '@/helpers/get-color'
import { LandscapeModule } from '@/modules/landscape/store'
import { OrganizationModule } from '@/modules/organization/store'
import { TeamModule } from '@/modules/team/store'
import getInitials from '@/modules/user/helpers/get-initials'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'

@Component({
  components: {
    Dialog
  },
  name: 'TeamUsersAddDialog'
})
export default class extends Vue {
  teamModule = getModule(TeamModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  searchTerm = ''
  loadingUserId: string | null = null
  addedUserIds: string[] = []

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return (this.$params.landscapeId || this.currentVersion?.landscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)!
  }

  get team () {
    return this.teamModule.teams.find(o => o.id === this.$route.query.team_users_add_dialog)!
  }

  get teamsListStatus () {
    return this.teamModule.teamsListStatus
  }

  get organizationUsersListStatus () {
    return this.organizationModule.organizationUsersListStatus
  }

  get organizationUsers () {
    return Object
      .entries(this.organizationModule.organizationUsers?.[this.currentOrganization.id] || {})
      .filter(([id]) => !this.team.userIds.includes(id) || this.addedUserIds.includes(id))
      .map(([id, o]) => ({
        ...o,
        id
      }))
  }

  get headers () {
    return [
      {
        text: 'Name',
        value: 'name',
        width: '25%'
      },
      {
        text: 'Email',
        value: 'email',
        width: '30%'
      },
      {
        text: 'Role',
        value: 'jobRole',
        width: '30%'
      },
      {
        text: 'Actions',
        value: 'actions',
        width: '15%'
      }
    ]
  }

  userColor (id?: string) {
    return id ? TAG_COLOR_ACTIVE[getColor(id)] : undefined
  }

  userInitials (name?: string) {
    return name ? getInitials(name) : undefined
  }

  opened () {
    analytics.teamUsersAddDialog.track(this, {
      organizationId: [this.team.organizationId],
      teamId: [this.team.id]
    })
  }

  closed () {
    this.searchTerm = ''
    this.addedUserIds = []
    this.loadingUserId = null
  }

  async addUser (userId: string) {
    try {
      this.loadingUserId = userId

      const userIds = [...this.team.userIds, userId]

      await this.teamModule.teamUpdate({
        organizationId: this.currentOrganization.id,
        teamId: this.team.id,
        update: {
          userIds
        }
      })

      this.addedUserIds.push(userId)

      analytics.teamUpdate.track(this, {
        organizationId: [this.currentOrganization.id],
        teamId: [this.team.id],
        userCount: userIds.length,
        userId: userIds
      })
    } finally {
      this.loadingUserId = null
    }
  }

  async removeUser (userId: string) {
    try {
      this.loadingUserId = userId

      const userIds = this.team.userIds.filter(o => o !== userId)

      await this.teamModule.teamUpdate({
        organizationId: this.currentOrganization.id,
        teamId: this.team.id,
        update: {
          userIds
        }
      })

      this.addedUserIds = this.addedUserIds.filter(o => o !== userId)

      analytics.teamUpdate.track(this, {
        organizationId: [this.currentOrganization.id],
        teamId: [this.team.id],
        userCount: userIds.length,
        userId: userIds
      })
    } finally {
      this.loadingUserId = null
    }
  }
}

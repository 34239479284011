
import { TAG_COLOR_ACTIVE } from '@icepanel/app-canvas'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import getColor from '@/helpers/get-color'
import { LandscapeModule } from '@/modules/landscape/store'
import { OrganizationModule } from '@/modules/organization/store'
import TeamUsersAddDialog from '@/modules/team/components/users-add-dialog.vue'
import { TeamModule } from '@/modules/team/store'
import getInitials from '@/modules/user/helpers/get-initials'
import { VersionModule } from '@/modules/version/store'

@Component({
  components: {
    TeamUsersAddDialog
  },
  name: 'TeamMemberTable'
})
export default class extends Vue {
  teamModule = getModule(TeamModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop({ type: Boolean }) readonly showHeaderText!: boolean

  searchTerm = ''
  deletingUserId: string | null = null
  copyingUserEmail: string | null = null
  copyingToClipboard: boolean = false
  copyEmailTimer?: number

  get teamsListStatus () {
    return this.teamModule.teamsListStatus
  }

  get currentTeamId () {
    return this.$queryValue('team')
  }

  get currentTeam () {
    return this.teamModule.teams.find(o => o.id === this.currentTeamId)
  }

  get teams () {
    return this.teamModule.teams
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return (this.$params.landscapeId || this.currentVersion?.landscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentOrganizationPermission () {
    return this.organizationModule.organizationPermission(this.currentOrganization)
  }

  get organizationUsersListStatus () {
    return this.organizationModule.organizationUsersListStatus
  }

  get teamUsers () {
    return Object
      .entries(this.currentOrganization ? this.organizationModule.organizationUsers?.[this.currentOrganization.id] || {} : {})
      .filter(([id]) => this.currentTeam?.userIds.includes(id))
      .map(([id, o]) => ({
        ...o,
        id
      }))
  }

  get headers () {
    if (this.currentOrganizationPermission === 'admin') {
      return [
        {
          text: 'Team member',
          value: 'name',
          width: '25%'
        },
        {
          text: 'Email',
          value: 'email',
          width: '25%'
        },
        {
          text: 'Role',
          value: 'jobRole',
          width: '25%'
        },
        {
          text: 'Action',
          value: 'actions',
          width: '25%'
        }
      ]
    } else {
      return [
        {
          text: 'Team member',
          value: 'name',
          width: '30%'
        },
        {
          text: 'Email',
          value: 'email',
          width: '30%'
        },
        {
          text: 'Role',
          value: 'jobRole',
          width: '30%'
        }
      ]
    }
  }

  destroyed () {
    clearTimeout(this.copyEmailTimer)
  }

  userColor (id?: string) {
    return id ? TAG_COLOR_ACTIVE[getColor(id)] : undefined
  }

  userInitials (name?: string) {
    return name ? getInitials(name) : undefined
  }

  async copyUserEmail (email: string) {
    if (this.copyingToClipboard) {
      return
    }
    this.copyingUserEmail = email
    this.copyingToClipboard = true
    await navigator.clipboard.writeText(email)
    this.copyingToClipboard = false
    clearTimeout(this.copyEmailTimer)
    this.copyEmailTimer = window.setTimeout(() => {
      this.copyingUserEmail = null
    }, 2000)
  }

  async deleteUser (userId: string) {
    try {
      if (!this.currentTeam) {
        throw new Error('Could not find current team')
      }
      if (!this.currentOrganization) {
        return
      }

      this.deletingUserId = userId

      const userIds = this.currentTeam.userIds.filter(o => o !== userId)

      await this.teamModule.teamUpdate({
        organizationId: this.currentOrganization.id,
        teamId: this.currentTeam.id,
        update: {
          userIds
        }
      })
    } finally {
      this.deletingUserId = null
    }
  }
}
